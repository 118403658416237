<template>
    <!--法律风险评估-->
    <div class="base-container">
        <div class="main-wrapper">
            <div class="module-container">
                <tabYq></tabYq>
            </div>
        </div>
    </div>
</template>

<script>
import tabYq from '@/views/legalRisk/tabYq.vue'
import {getStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
    name: "index",
    components: {
        tabYq:tabYq
    },
    data() {
        return {
            activeName: 'cu',
            num: {
                unitCount: 0,
                projectCount: 0,
                opinionCount: 0
            },
        }
    },
    created() {
        this.getSeatchCount();
        if(this.$route.query.activeName){
            this.activeName = this.$route.query.activeName
        }
    },
    mounted() {
        // if (window.history && window.history.pushState) {
        //     history.pushState(null, null, document.URL);
        //     // 给 popstate 绑定一个方法 监听页面刷新
        //     window.addEventListener('popstate', this.back, false);
        // }
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        // back() {
        //     this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
        //     this.reducePath();//删除导航最后一个路由
        //     var currentMenu = getStore("currentMenu");
        //     this.$router.push({path: currentMenu});
        // },
        titleTab(title) {
            this.activeName = title;
        },
        getSeatchCount() {
            let projectId = getStore('thisProject').id;
            let userId = getLocalStore('userinfo').id;
            this.axios.request({
                loading: true,
                method: "post",
                url: '/dms/getSearchCount',
                 
                params: {
                    projectId: projectId,
                    userId: userId,
                }
                // data: {companyName:'秦皇岛世纪港湾房地产开发有限公司',xmid:'90006349'},
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                } else if (res.data.status == "success") {
                    this.num = res.data.data;
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.top-tabs {
    display: flex;
    .tab-item {
        padding: 10px;
        border-radius: 2px;
        cursor: pointer;
    }
    .selected {
        color: #FFFFFF;
        background: #185BCE;
    }
}
.main-wrapper{
    height: 100%;
}
.module-container {
    width: 100%;
    height: calc(100% - 49px);
}


</style>