<template>
    <div id="yqScroll" class="base-container">
        <div class="main-wrapper">
            <div class="search_box">
                <div class="tab-item">舆情风险 {{ total }}</div>
                <div class="search">
                    <div class="left-search">
                        <el-input class="search-content" size="medium" placeholder="输入任意内容关键字进行查询" v-model="keyWord"></el-input>
                        <el-button type="primary" size="small" @click="getTableList('search')">查询</el-button>
                    </div>
                </div>
            </div>
            
            
           
            <div class="disputes-info">
                <div class="waterfall">
                    <my-waterfall :data="cardList"></my-waterfall>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
import myWaterfall from "@/components/myWaterfall.vue";
let scrollDom;
export default {
    components: { myWaterfall },
    name: "tabCU",
    filters: {
    },
    data() {
        return {
            pageCount:null,
            activeName: "1",
            keyStr: '',
            proName: getStore('thisProject').proName,
            editableTabs: [{}],
            projectList: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            checkBox: '全部',
            Options: [],
            taxpayerName: getStore('thisProject').taxpayerName,
            cardList:[],
            renderFlag: true,
            keyWord: ''

        }
    },
    mounted() {
        this.getTableList('all');
        // window.addEventListener('scroll',this.handleScroll,true)
        scrollDom = document.getElementById('yqScroll')
        scrollDom.addEventListener('scroll',this.handleScroll)
        
    },
    beforeDestroy(){
        scrollDom.removeEventListener("scroll",this.handleScroll)
    },
    created(){
        this.getReport(0,'','')
    },
    destroyed(){
        this.getReport(1,'','')
    },
    watch:{
        pageCount(){
            return this.total/this.count;
        }
    },
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'publicSentiment',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取舆情数据
        getTableList(type) {
            if(type=='search'){
                 this.getReport(2,'search','searchBtn')
                this.currentPage = 1;
                this.cardList = [];
            }
            this.axios({
                method: "post", url: '/dms/pageProjectNews',
                 
                data: {
                    company: getStore('thisProject').taxpayerName,
                    pageNum: this.currentPage,
                    pageSize: 20,
                    projectName: this.proName,
                    projectId: getStore('thisProject').id,
                    keyWord: this.keyWord
                },
            }).then((res) => {
                if(res.data.data.records.length==0){
                    this.$message.warning('暂无数据')
                    return;
                }
                this.cardList = this.cardList.concat(res.data.data.records);
                this.total = res.data.data.total;
                
                setTimeout(() => {
                    this.renderFlag = true;
                }, 1500);
         
              
            }).catch((error) => {
                console.log(error)
            })
        },
     
        handleScroll(){
            let scrollTop = document.getElementsByClassName('base-container')[0].scrollTop;
            let mainHeight = document.getElementsByClassName('main-wrapper')[0].clientHeight;
            if((mainHeight-scrollTop)<800 && this.renderFlag){
                this.renderFlag = false;
                this.currentPage++;
                this.getTableList('all');
            }
        },

    }
}
</script>

<style scoped lang="less">
@import "../../assets/css/legal-risk.less";
.disputes-info{
    padding-top: 60px;
}
</style>