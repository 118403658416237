<template>
  <div>
    <waterfall   :col="col" :width="itemWidth" :gutterWidth="gutterWidth" :data="data" >
      <template>
        <div class="cell-item" v-for="(item, index) in data" :key="index">
          <div class="item-body">
            <div class="item-title">{{item.title}}</div>
            <div class="item-tag">{{item.province}} / {{item.city}}</div>
            <div class="item-text">{{item.content}}</div>
            <div class="item-bottom">
              <div class="item-bq">
                <div class="bq">{{item.category}}</div>
                <div class="bq">{{item.type}}</div>
              </div>
              <div class="item-time">{{item.postTime}}</div>
            </div>
          </div>
        </div>
      </template>
    </waterfall>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    wfHeight: String,

  },
  data() {
    return {
      col: 3,
    };
  },
  mounted(){
  },
  computed:{
        itemWidth(){  
	        return 370
	      },
	      gutterWidth(){
	        return 24
	      }
	    },
  methods: {
    
  },
};
</script>
<style lang="less" scoped>
.item-body {
  .item-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .item-tag{
    font-size: 14px;
    color: #969799;
    margin-bottom: 16px;
  }
  .item-text{
    font-size: 14px;
    color: #646566;
    line-height: 20px;
    margin-bottom: 32px;
  }
  .item-bottom{
    overflow: hidden;
    .item-bq{
      float: left;
      .bq{
        float: left;
        margin-right: 8px;
        font-size: 12px;
        width: 40px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #F2F3F5;
        color: #646566;
      }
    }
    .item-time{
      float: right;
      color: #969799;
      line-height: 20px;
    }
  }
  
}
.cell-item {
  width: 100%;
  background: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 24px;
}
</style>